<template>
  <div>
    <div class="flex justify-center">
      <div class="flex h-full w-full flex-col justify-center p-24 md:w-[34rem]">
        <RevStepper
          :active-step="STEP_NAMES.ADDRESS"
          :alternative-text-back="i18n(translations.previousPage)"
          :alternative-text-close="i18n(translations.stepperClose)"
          :alternative-text-completed="i18n(translations.stepperCompleted)"
          :alternative-text-current="i18n(translations.stepperCurrent)"
          :has-close="false"
          :hasBack="hasBackButton"
          :steps
          @back="goBack"
        />
        <div class="flex flex-col justify-start pt-32">
          <h2 class="body-1-bold">
            {{ i18n(translations.formTitle) }}
          </h2>
          <p class="body-1 my-16">
            {{ i18n(translations.formDescription) }}
          </p>
          <FormGenerator
            activate-address-validation-error-logger
            :form-config
            :form-id="ADDRESS_FORM"
            :has-submit-button="false"
            @submit="handleSubmit"
          />

          <small v-if="buybackConfig.legalMentions" class="caption mt-24">
            <FormattedMessage
              data-test="partner-content"
              :definition="translations.legalText"
            >
              <template #link>
                <RevLink target="_blank" :to="legalLink">
                  {{ i18n(translations.legalLinkText) }}
                </RevLink>
              </template>
            </FormattedMessage>
          </small>

          <RevButton
            id="address-submit"
            class="ml-auto mt-24"
            data-qa="adress-submit"
            :disabled="isLoading"
            :form="ADDRESS_FORM"
            full-width="adaptive"
            :loading="isLoading"
            type="submit"
            variant="primary"
          >
            {{ i18n(translations.formSubmitButton) }}
          </RevButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from '#imports'
import { inject, onMounted, ref } from 'vue'

import { postOrder } from '@backmarket/http-api/src/api-specs-buyback/customer/customer'
import { getBuyBackAddress } from '@backmarket/http-api/src/api-specs-buyback/customer/getAddress'
import { postBuyBackAddress } from '@backmarket/http-api/src/api-specs-buyback/customer/postAddress'
import { HttpApiError } from '@backmarket/http-api/src/utils/HttpApiError'
import type {
  FormValues,
  InputBase,
} from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'
import FormGenerator from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.vue'
import { useBuybackConfig } from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useDynamicAddressFieldValidators } from '@backmarket/nuxt-module-address/useDynamicAddressFieldValidators'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { getPhoneNumberInfos } from '@ds/components/InputPhone'
import { RevLink } from '@ds/components/Link'
import { RevStepper } from '@ds/components/Stepper'
import { type CountryCode } from 'libphonenumber-js'

import { useBuybackFunnelOffer } from '~/scopes/buyback/composables/useBuybackFunnelOffer'
import { useResaleStepper } from '~/scopes/buyback/composables/useResaleStepper'
import { useGenerateAddressForm } from '~/scopes/buyback/config/useGenerateAddressForm'
import {
  ERROR_TYPES,
  ROUTE_NAMES,
  STEP_NAMES,
} from '~/scopes/buyback/constants'
import { FUNNEL_ERROR_MESSAGE } from '~/scopes/buyback/pages/constants'
import { CMS } from '~/scopes/cms/routes-names'

import type { ErrorData } from '../components/TheCatcher/useCatcher'

import translations from './Address.translations'

const route = useRoute()
const router = useRouter()
const { offer } = useBuybackFunnelOffer()
const currentLocale = useI18nLocale()
const buybackConfig = useBuybackConfig()

const { openErrorToast } = useTheToast()
const i18n = useI18n()
const { user } = useUserStore()
const tracking = useTracking()
const logger = useLogger()
const { getDynamicAddressFieldValidators } = useDynamicAddressFieldValidators()
const { steps, goBack, hasBackButton } = useResaleStepper({
  activeStepName: STEP_NAMES.ADDRESS,
  hasShippingStep: offer.value?.has_shipping_choices,
})

const isLoading = ref(false)
const addressPublicId = ref('')

const errorData = inject<ErrorData>('errorData', ref({ message: '', step: '' }))
errorData.value = {
  message: FUNNEL_ERROR_MESSAGE.BUYBACK_FUNNEL,
  step: 'Address',
}

const ADDRESS_FORM = 'address-form'
const legalLink = `${currentLocale}/legal/data-protection`

const { data } = await useHttpFetch(getBuyBackAddress)

// If customer address is not existing the API resolves in 404
// in this case it will fail silently and user will have to fill in the address
const initialAddressFormConfig = useGenerateAddressForm(data.value)

const initialFormValidation = initialAddressFormConfig.reduce(
  (acc, input) => {
    return {
      ...acc,
      [input.id]: input.validation || [],
    }
  },
  {} as Record<string, InputBase['validation']>,
)

const addressValidation = await getDynamicAddressFieldValidators({
  featureFlag: 'FF_ENABLE_ADDRESS_FIELD_VALIDATION_TRADEIN',
  initialFormValues: initialFormValidation,
  fallbackValidators: initialFormValidation,
  metadata: {
    scope: 'customer',
  },
})

const formConfig = initialAddressFormConfig.map((input) => {
  return {
    ...input,
    validation:
      (addressValidation[
        input.id as keyof typeof addressValidation
      ] as InputBase['validation']) || input.validation,
  }
})

async function handleSubmit(values: FormValues) {
  isLoading.value = true
  tracking.trackClick({
    zone: 'buyback',
    name: 'personal_info',
    value: {
      category: route.params?.id,
    },
  })

  const { dial, nationalNumber } = getPhoneNumberInfos(
    values.phone as string,
    values.country as CountryCode,
  )
  const body = {
    ...values,
    firstName: user.firstName,
    lastName: user.lastName,
    countryDialInCode: dial,
    phone: nationalNumber,
  }

  try {
    const payload = await $httpFetch(postBuyBackAddress, {
      body,
    })

    addressPublicId.value = payload?.public_id
  } catch (errors) {
    logger.error(FUNNEL_ERROR_MESSAGE.POST_ADDRESS, {
      owners: ['bot-squad-circularity-customer-front'],
    })

    let formattedErrors = ''
    Object.keys(body).forEach((key) => {
      const err = (errors as Record<string, Array<string>>)[key]
      if (err) {
        formattedErrors += `${err.join('\n')}\n`
      }
    })

    openErrorToast({
      content: formattedErrors,
    })
    isLoading.value = false

    return
  }

  if (offer.value?.has_shipping_choices && addressPublicId.value) {
    router.push({
      name: ROUTE_NAMES.SHIPPING,
      params: {
        ...route.params,
        addressId: addressPublicId.value,
      },
      query: route.query,
    })
  } else if (addressPublicId.value) {
    try {
      const payload = await $httpFetch(postOrder, {
        body: {
          listing_id: route.params?.listingId,
          customerAddressId: addressPublicId.value,
          shippingId: offer.value?.defaultShippingModeId,
        },
      })

      tracking.trackBuybackConfirmation({
        buybackType: 'bb_normal',
        buybackOrderId: payload.id,
        commission: payload.estimatedCommission?.amount,
        conversionValue: payload.price,
        conversionCurrency: payload.estimatedCommission?.currency,
      })

      router.push({
        name: ROUTE_NAMES.CONFIRMATION,
        params: {
          id: payload.id,
        },
        query: route.query,
      })
    } catch (postOrderError) {
      isLoading.value = false
      if (postOrderError instanceof HttpApiError) {
        if (postOrderError.type === ERROR_TYPES.INVALID_ID_ERROR_TYPE) {
          openErrorToast({
            content: i18n(translations.formErrorUnableToProcess),
          })
        } else if (postOrderError.type === ERROR_TYPES.NOT_FOUND_ERROR_TYPE) {
          openErrorToast({
            content: i18n(translations.formErrorListingNotFound),
          })
        } else {
          openErrorToast({
            content: i18n(translations.formApiError),
          })
        }
      } else {
        openErrorToast({
          content: i18n(translations.formApiError),
        })
      }
    }
  }
}

onMounted(() => {
  // If no offer has been found in local storage, redirect to home
  if (!offer.value) {
    openErrorToast({
      title: 'Sorry!',
      content: 'Trade-in offer is no longer valid',
    })

    void router.push({
      name: CMS.BUYBACK,
      params: {
        pageName: 'home',
      },
      ...((route?.query?.partner && {
        query: { partner: route.query.partner },
      }) ||
        {}),
    })
  }
})
</script>
